import http from '@/api';

let timer;
export async function startUpdateToken() {
  const refreshToken = localStorage.getItem('refresh-token');
  const time = localStorage.getItem('time-update-token');
  if (refreshToken) {
    timer = setInterval(() => {
      http.post('api/refresh-token', {
        refresh_token: refreshToken,
      }).then((res) => {
        localStorage.setItem('jwt', res.data.access_token);
        localStorage.setItem('refresh-token', res.data.refresh_token);
      }).catch((error) => {
        console.log(error.response.data);
      });
    }, (time - 30) * 100);
  }
}

export async function stopUpdateToken() {
  clearInterval(timer);
  localStorage.setItem('jwt', '');
  localStorage.setItem('refresh-token', '');
  localStorage.setItem('time-update-token', '');
}
