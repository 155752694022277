<template>
  <v-sheet
    style="overflow:none;"
    elevation="6"
    class="pa-3 header-nav-bar"
  >
    <div class="d-flex align-center">
      <router-link
        class="router-link mr-10"
        to="/main"
      >
        Лифт
      </router-link>
      <router-link
        class="router-link mr-10"
        to="/film"
      >
        Тристок фильмы
      </router-link>
      <router-link
        class="router-link mr-10"
        to="/film-welding"
      >
        Фильм цеха сварки
      </router-link>
      <v-btn
        class="ml-auto"
        color="primary"
        @click="exit"
      >
        <v-icon
          class="mr-6"
          color="light-gray"
        >
          mdi-exit-to-app
        </v-icon>
        Выход
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { logout } from '../api/logout.js';

export default {
  data: () => ({
  }),
  methods: {
    exit() {
      logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-nav-bar {
  position: fixed;
  width: 100%;
  z-index: 2;
}
.router-link {
  color: $baseText !important;
  text-decoration: none;
  transition: 0.2s;

  &--disable {
    color: $subText !important;
  }
  &-active {
    color: $primary !important;
    text-decoration: underline;
  }

  &:hover {
    color: $subText !important;
  }
}
</style>
