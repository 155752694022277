<template>
  <v-app>
    <header-nav-bar v-if="!isAuthorizationPage" />
    <v-main class="main">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { startUpdateToken } from '@/api/updateToken';
import HeaderNavBar from './views/HeaderNavBar.vue';

export default {
  name: 'App',
  components: { HeaderNavBar },

  data: () => ({
    //
  }),
  computed: {
    isAuthorizationPage() {
      return this.$route.name === 'AuthPage';
    },
  },
  mounted() {
    startUpdateToken();
  },
};
</script>
<style scoped lang="scss">
.main {
  margin-top: 76px;
}
</style>
