import Vue from 'vue';
import moment from 'moment';
import Http from '@/api';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import '@/assets/style/index.scss';

Vue.config.productionTip = false;

Vue.prototype.$http = Http;
Vue.prototype.$moment = moment;

moment.locale('ru');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
