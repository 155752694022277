import { stopUpdateToken } from './updateToken';

export async function logout() {
  window.location.href = '/';
  stopUpdateToken();
  localStorage.setItem('isAuthorization', false);
}

export default {
  logout,
};
