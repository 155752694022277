import axios from 'axios';
import { logout } from './logout';

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwt');
  if (config.url === 'api/login') {
    return config;
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.response.data.message === 'Unauthorized' && !originalRequest._retry) {
      logout();
    }
    return Promise.reject(error);
  },
);
export default axios;
