import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/main',
    name: 'home',
    component: () => import('@/views/MainPage.vue'),
  },
  {
    path: '/',
    name: 'AuthPage',
    component: () => import('@/views/AuthPage.vue'),
  },
  {
    path: '/film',
    name: 'FilmTistock',
    component: () => import('@/views/FilmTristock.vue'),
  },
  {
    path: '/rules-editor',
    name: 'RulesEditon',
    component: () => import('@/components/Films/RulesEditor.vue'),
  },
  {
    path: '/film-welding',
    name: 'FilmGuildWelding',
    component: () => import('@/views/FilmGuildWelding.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
